import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <section className="footer__copyright-notice">
          &copy; Copyright {new Date().getFullYear()}. St. Jude Children's
          Research Hospital, a not-for-profit, section&nbsp;501(c)(3).
        </section>

        <nav className="footer__links">
          <ul className="footer-links">
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/u-s-privacy-policy-statement.html"
                title="Privacy Notice"
              >
                U.S. Privacy Notice
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal.html"
                title="Disclaimer / Registrations / Copyright Statement"
              >
                Disclaimer / Registrations / Copyright Statement
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/linking-policy.html"
                title="Linking Policy"
              >
                Linking Policy
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/notice-of-privacy-practices.html"
                title="Notice of Privacy Practices (HIPAA)"
              >
                Notice of Privacy Practices (HIPAA)
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/u-s-privacy-policy-statement.html#advertising"
                title="Ad Choices"
              >
                Ad Choices
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/notice-of-non-discrimination.html"
                title="Notice of Non-Discrimination"
              >
                Notice of Non-Discrimination
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/standard-charges.html"
                title="Pricing Transparency"
              >
                Pricing Transparency
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/sitemap.html"
                title="Site Map"
              >
                Site Map
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/your-opt-out-rights.html"
                title="Your Opt-Out Rights"
              >
                Your Opt-Out Rights
              </a>
            </li>
            <li className="footer-links__link-wrapper">
              <a
                className="footer-links__link"
                href="https://www.stjude.org/legal/u-s-privacy-policy-statement/washington-health-privacy-notice.html"
                title="Washington Health Privacy Notice"
              >
                Washington Health Privacy Notice
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    );
  }
}
